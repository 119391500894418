import React, { useEffect, useState } from 'react';
import styles from './Header.module.scss';




const Header = ({ destination }) => {

  const [sectionActive, setSection] = useState(destination);
  // This will launch only if propName value has chaged.
  useEffect(() => { 
    setSection(destination) ;
  }, [destination]);

  const [isTouchScreen] = useState(('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0));


  return (
    <header className={styles.header}  >
      <div className={styles.navbar} data-testid="Header">
        <span id={styles['logo-title']}>DevFolio</span>
        <div className="navigation-row">
          <nav id="navigation">
            <ul id={styles.nav}>
              <li data-menuanchor="section-home"><a className={`${sectionActive === "" || sectionActive.anchor === "section-home" ? styles['section-active'] : ""} ${isTouchScreen ? "" : styles['not-touch-screen']}`} {...window.screen.height > 600 ? { href: "/#" } : { href: "#home" }} onClick={() => window.fullpage_api.moveTo('section-home', 0)}>Home</a></li>
              <li data-menuanchor="section-about"><a className={`${sectionActive.anchor === "section-about" ? styles['section-active'] : ""} ${isTouchScreen ? "" : styles['not-touch-screen']}`} {...window.screen.height > 600 ? { href: "/#" } : { href: "#about" }} onClick={() => window.fullpage_api.moveTo('section-about', 0)}>About Me</a></li>
              <li data-menuanchor="section-skills"><a className={`${sectionActive.anchor === "section-skills" ? styles['section-active'] : ""} ${isTouchScreen ? "" : styles['not-touch-screen']}`} {...window.screen.height > 600 ? { href: "/#" } : { href: "#skills" }} onClick={() => window.fullpage_api.moveTo('section-skills', 0)}>Skills</a></li>
              <li data-menuanchor="section-experiences"><a className={`${sectionActive.anchor === "section-experiences" ? styles['section-active'] : ""} ${isTouchScreen ? "" : styles['not-touch-screen']}`} {...window.screen.height > 600 ? { href: "/#" } : { href: "#experiences" }} onClick={() => window.fullpage_api.moveTo('section-experiences', 0)}>Experiences</a></li>
            </ul>
          </nav>
        </div>
      </div>
    </header>
  );
}

Header.propTypes = {};

Header.defaultProps = {};

export default Header;
