import React, { useState, useEffect } from 'react';
import styles from './SocialSideBar.module.scss';
import { faFacebook, faGithub, faGitlab, faInstagram, faLinkedinIn } from '@fortawesome/free-brands-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCopy, faEnvelope } from '@fortawesome/free-regular-svg-icons';
import { useToasts } from 'react-toast-notifications'
import { faUndo } from '@fortawesome/free-solid-svg-icons';


const getWindowDimensions = function () {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height
  };
}

const useWindowDimensions = function() {
  const [windowDimensions, setWindowDimensions] = useState(getWindowDimensions());

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return windowDimensions;
}


const SocialSideBar = () => {

  let instagramIcon = null;
  let undoIcon = null;

  const { height, width } = useWindowDimensions();
  const [countInstaClick, setCountInstaClick] = useState(0);

  const [isTouchScreen] = useState(('ontouchstart' in window) || (navigator.maxTouchPoints > 0) || (navigator.msMaxTouchPoints > 0));



  const { addToast } = useToasts();



  const copyMail = function () {
    navigator.clipboard.writeText('minhtrieu.ha@outlook.com');
    addToast('Copied to clipboard.', { autoDismiss: true, appearance: 'success' });
  };

  const instagramNotification = function () {
    moveRandomly();

    if(countInstaClick === 0 ) {
      addToast('Sorry I do not have Instagram.', { autoDismiss: true, appearance: 'info' });
    }
    if(countInstaClick === 1 ) {
      addToast('Why would you want my Instagram anyway ?', { autoDismiss: true, appearance: 'warning' });
    }

    if(countInstaClick === 2 ) {
      addToast('You are losing your time...', { autoDismiss: true, appearance: 'error' });
    }

    if(countInstaClick === 3 ) {
      addToast('Are you having fun ?', { autoDismiss: true, appearance: 'success' });
    }

    setCountInstaClick(countInstaClick + 1);
  };

  const moveRandomly = function () {
    var x = Math.floor(Math.random() * width / 3);
    var y = Math.ceil(Math.random() * height / 3) * (Math.round(Math.random()) ? 1 : -1);
    instagramIcon.style.top = -y + 'px';
    instagramIcon.style.left = 'calc(' + -x + 'px - 2rem)';
    instagramIcon.style.position = 'absolute';
    undoIcon.style.display = 'block'

  };



  return (
    <div className={styles['social-side-bar']} data-testid="SocialSideBar">
      <div className={styles['social-icons-list']} >
        <div title="Mail" className={`${styles['social-icon-link']}`}>
          <input type="checkbox" id={styles['mail-hidden-checkbox']} />
          <label htmlFor={styles['mail-hidden-checkbox']}>
            <FontAwesomeIcon icon={faEnvelope}  id={styles['mail-icon']} className={`${styles['social-icon']} ${isTouchScreen ? "" : styles['not-touch-screen']}`} />
          </label>
          <dialog id={styles['mail-dialog']}  >
            <p>Contact me on: minhtrieu.ha@outlook.com</p>

            <div className={styles['action-button']}>
              <FontAwesomeIcon icon={faCopy} className={styles['copy-icon']} onClick={copyMail} />
              <label htmlFor={styles['mail-hidden-checkbox']}>Close</label>
            </div>
          </dialog>

        </div>
        <a title="LinkedIn" href="https://www.linkedin.com/in/minh-trieu-ha/" target="_blank" rel="noopener noreferrer" className={`${styles['social-icon-link']} ${isTouchScreen ? "" : styles['not-touch-screen']}`}>
          <FontAwesomeIcon icon={faLinkedinIn} className={`${styles['social-icon']}`} />
        </a>
        <a title="GitHub" href="https://github.com/Minh-Trieu" target="_blank" rel="noopener noreferrer" className={`${styles['social-icon-link']} ${isTouchScreen ? "" : styles['not-touch-screen']}`}>
          <FontAwesomeIcon icon={faGithub} className={`${styles['social-icon']}`} />
        </a>
        <a title="GitLab" href="https://gitlab.com/Minh-Trieu" target="_blank" rel="noopener noreferrer" className={`${styles['social-icon-link']} ${isTouchScreen ? "" : styles['not-touch-screen']}`}>
          <FontAwesomeIcon icon={faGitlab} className={`${styles['social-icon']}`} />
        </a>
        <a title="Facebook" href="https://www.facebook.com/minhtrieu.ha" target="_blank" rel="noopener noreferrer" className={`${styles['social-icon-link']} ${isTouchScreen ? "" : styles['not-touch-screen']}`}>
          <FontAwesomeIcon icon={faFacebook} className={`${styles['social-icon']}`} />
        </a>
        <div title="Instagram" ref={(element) => { instagramIcon = element }} className={`${styles['social-icon-link']} ${isTouchScreen ? "" : styles['not-touch-screen']}`} id={styles['instagram-icon']} onMouseEnter={moveRandomly} onClick={instagramNotification}  >
          <FontAwesomeIcon icon={faInstagram} className={`${styles['social-icon']}`} />
        </div>
        <div title="Undo" ref={(element) => { undoIcon = element }} className={`${styles['social-icon-link']} ${isTouchScreen ? "" : styles['not-touch-screen']} ${styles['invisible']}`}>
          <FontAwesomeIcon icon={faUndo} className={`${styles['social-icon']} ${styles['hidden']} `} />
        </div>
      </div>

    </div>
  );
}



SocialSideBar.propTypes = {};

SocialSideBar.defaultProps = {};

export default SocialSideBar;
