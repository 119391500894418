import React from 'react';
import styles from './About.module.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faDatabase, faHourglassHalf, faTachometerAlt } from '@fortawesome/free-solid-svg-icons'
import { faCogs } from '@fortawesome/free-solid-svg-icons'
import { faLaptopCode } from '@fortawesome/free-solid-svg-icons'
import FadeIn from 'react-fade-in';
import { useInView } from 'react-intersection-observer';

const About = () => {
  const [ref, inView] = useInView({
    triggerOnce: true,
    rootMargin: '200px 0px',
  });

  const yearsOfExperiences = Math.abs(new Date(Date.now() - new Date("2012-06-01")).getFullYear() - 1970);


  return (<div ref={ref}>
    <section id="about" data-testid="About">
      <FadeIn className={styles.about} delay="50" transitionDuration="500">
        <div>
          <FadeIn delay="50" transitionDuration="3000">
            <div className={styles['about-statistics']}>

              <h2 className={styles['about-title']}>About Me</h2>
              <p>As most of my former Software developer colleagues, I am versatile and curious enough to learn new technologies by myself, but instead of coffee as energy refill, I'd rather rely on bubble tea.
        Just like many other developers, I can be labeled as a "Gamer", but maybe just a casual one. And like many other millennials, I love travelling, but I prefer mountains over beaches.</p>
              <div className={styles['statistics-container']}>
                <div className={styles['statistics-frame']}>
                  <FontAwesomeIcon icon={faHourglassHalf} className={styles['statistics-icons']} />
                  <span className={styles['count-number']}>{yearsOfExperiences}</span>
                  <span>Years of Experience</span>
                </div>
                <div className={styles['statistics-frame']}>
                  <FontAwesomeIcon icon={faCogs} className={styles['statistics-icons']} />
                  <span className={styles['count-number']}>6</span>
                  <span>Different Missions</span>
                </div>
                <div className={styles['statistics-frame']}>
                  <img className={`${styles['statistics-icons']} ${styles['bubble-tea']}`} src="/assets/images/bubble_tea_icon.svg" alt="Bubble Tea Icon" />
                  <span className={styles['count-number']}>Too much</span>
                  <span>Bubble tea</span>
                </div>
              </div>
            </div>

          </FadeIn>
          <FadeIn delay="1500" transitionDuration="3000">
            <div className={styles['about-statistics']} >
              <h2 className={styles['about-title']}>Here are some of my expertise</h2>
              <div className={styles['expertise-container']}>
                <div className={styles['expertise-frame']}>
                  <span className={`${styles['expertise-icon']} ${styles['blue-gray']}`} >
                    <FontAwesomeIcon icon={faLaptopCode} className={styles['statistics-icons']} />
                  </span>
                  <div className={styles['expertise-desc']}>
                    <h3>Full Stack</h3>
                    <p>Build web applications using Java, Angular.</p>
                  </div>
                </div>

                <div className={styles['expertise-frame']}>
                  <span className={`${styles['expertise-icon']} ${styles['blue-gray']}`} >
                    <FontAwesomeIcon icon={faDatabase} className={styles['statistics-icons']} />
                  </span>
                  <div className={styles['expertise-desc']}>
                    <h3>Database</h3>
                    <p>Develop SQL script and stored procedure on Oracle, Sybase.</p>
                  </div>
                </div>

                <div className={styles['expertise-frame']}>
                  <span className={`${styles['expertise-icon']} ${styles['blue-gray']}`} >
                    <FontAwesomeIcon icon={faTachometerAlt} className={styles['statistics-icons']} />
                  </span>
                  <div className={styles['expertise-desc']}>
                    <h3>DevOps</h3>
                    <p>Improve CI/CD with tools like Jenkins, Docker, K8s, Ansible and build tools to improve team productivity.</p>
                  </div>
                </div>
              </div>
            </div>
          </FadeIn>
        </div>
        {inView &&
          <div className={styles['profil-photo']}>

            <figure>
              <picture>
                <source media="(min-width: 1251px)" srcSet="assets/images/profil_photo_minh-trieu_ha_699.jpg" />

                <source media="(min-width: 1025px)  and (max-width: 1251px)" srcSet="assets/images/profil_photo_minh-trieu_ha_640.jpg" />
                <source media="(min-width: 769px)  and (max-width: 1024px)" srcSet="assets/images/profil_photo_minh-trieu_ha_320.jpg" />
                <source media="(min-width: 321px)  and (max-width: 768px)" srcSet="assets/images/profil_photo_minh-trieu_ha_200.jpg" />

                <source media="(max-width: 320px)" srcSet="assets/images/profil_photo_minh-trieu_ha_150.jpg" />
                <img src="/assets/images/profil_photo_minh-trieu_ha_699.jpg"
                  alt="Minh-Trieu HA and a Raven Friend." title="Minh-Trieu HA profil photo" />
              </picture>

              <figcaption>My raven friend and me...</figcaption>
            </figure>
          </div>
        }
      </FadeIn>
    </section >
  </div>
  );
};

About.propTypes = {};

About.defaultProps = {};

export default About;
