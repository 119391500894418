import Home from './components/Home/Home';
import About from './components/About/About';
import MySkills from './components/MySkills/MySkills';
import ReactFullpage from "@fullpage/react-fullpage";
import './App.scss';
import { useState } from 'react';
import Header from './components/Header/Header';
import Experiences from './components/Experiences/Experiences'
import SocialSideBar from './components/SocialSideBar/SocialSideBar';
import { ToastProvider } from 'react-toast-notifications'
import { properties } from './shared/properties/properties'


function App() {

  const [destination, setDestination] = useState('');

  const onLeave = function onLeave(origin, destination, direction) {
    setDestination(destination);
  };
  return (
    <ToastProvider placement='bottom-center' autoDismissTimeout='3000'>
      <div>
        <Header destination={destination} />
        <SocialSideBar />
        <video autoPlay muted loop id="background-video">
          <source media="(min-width: 769px)" src="/assets/video/background_video_minh-trieu_ha.mp4" type="video/mp4" />
          <source media="(max-width: 768px)" src="/assets/video/background_video_minh-trieu_ha_sd.mp4" type="video/mp4" />
        </video>
        <ReactFullpage
          scrollOverflow={true}
          licenseKey={properties.fullPageLicenseKey}
          responsiveHeight="590"
          responsiveWidth="426"
          onLeave={onLeave.bind(this)}


          render={({ state, fullpageApi }) => {
            return (
              <div className="app">
                <div id="fullpage-wrapper">
                  <div className="section" data-anchor="section-home">
                    <Home fullpageApi={fullpageApi} />
                  </div>
                  <div className="section" data-anchor="section-about">
                    <About />
                  </div>
                  <div className="section" data-anchor="section-skills">
                    <MySkills />
                  </div>
                  <div className="section" data-anchor="section-experiences">
                    <Experiences />
                  </div>
                </div>
              </div>
            );
          }}
        />
      </div>
    </ToastProvider>
  );
}


export default App;
