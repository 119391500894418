import React from "react";
import styles from './MySkills.module.scss';
import FadeIn from 'react-fade-in';




const MySkills = () => {



  return (
    <section id="skills" data-testid="MySkills">
      <FadeIn delay="50" transitionDuration="3000">
        <h2 className={styles['skills-title']}>My Skills</h2>



        <div className={styles['skills-container']}>


          <div className={`${styles['skills-statistics']} ${styles['slider-container-transition']}`}>
            <input type="radio" name="slider" title="languagues" defaultChecked className={styles['slider-nav']} />
            <input type="radio" name="slider" title="frameworks" className={styles['slider-nav']} />
            <input type="radio" name="slider" title="misc" className={styles['slider-nav']} />
            <input type="radio" name="slider" title="continous-integration" className={styles['slider-nav']} />
            <div className={styles['skills-category-container']}>
              <h3 className={styles['skills-category-name']}>Languages</h3>

              <div className={styles['skills-progess-container']}>
                <h3>Java 8 - Java EE 7</h3>
                <div className={styles['skills-progess']} >
                  <div className={`${styles['skills-progess-bar']} ${styles['color-blue']}`} role="progressbar" aria-valuenow="85" aria-valuemin="0" aria-valuemax="100" style={{ width: 85 + '%' }}>
                    <span>85%</span>
                  </div>
                </div>
              </div>

              <div className={styles['skills-progess-container']}>
                <h3>Groovy</h3>
                <div className={styles['skills-progess']} >
                  <div className={`${styles['skills-progess-bar']} ${styles['color-blue']}`} role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style={{ width: 70 + '%' }}>
                    <span>70%</span>
                  </div>
                </div>
              </div>

              <div className={styles['skills-progess-container']}>
                <h3>Javascript</h3>
                <div className={styles['skills-progess']} >
                  <div className={`${styles['skills-progess-bar']} ${styles['color-blue']}`} role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style={{ width: 70 + '%' }}>
                    <span>70%</span>
                  </div>
                </div>
              </div>

              <div className={styles['skills-progess-container']}>
                <h3>SQL</h3>
                <div className={styles['skills-progess']} >
                  <div className={`${styles['skills-progess-bar']} ${styles['color-blue']}`} role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style={{ width: 70 + '%' }}>
                    <span>70%</span>
                  </div>
                </div>
              </div>

              <div className={styles['skills-progess-container']}>
                <h3>TypeScript</h3>
                <div className={styles['skills-progess']} >
                  <div className={`${styles['skills-progess-bar']} ${styles['color-blue']}`} role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style={{ width: 70 + '%' }}>
                    <span>70%</span>
                  </div>
                </div>
              </div>

              <div className={styles['skills-progess-container']}>
                <h3>Shell Script</h3>
                <div className={styles['skills-progess']} >
                  <div className={`${styles['skills-progess-bar']} ${styles['color-blue']}`} role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100" style={{ width: 60 + '%' }}>
                    <span>60%</span>
                  </div>
                </div>
              </div>
            </div>

            <div className={styles['skills-category-container']}>
              <h3 className={styles['skills-category-name']}>Frameworks</h3>

              <div className={styles['skills-progess-container']}>
                <h3>Spring - Spring boot</h3>
                <div className={styles['skills-progess']} >
                  <div className={`${styles['skills-progess-bar']} ${styles['color-duck-blue']}`} role="progressbar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100" style={{ width: 80 + '%' }}>
                    <span>80%</span>
                  </div>
                </div>
              </div>


              <div className={styles['skills-progess-container']}>
                <h3>Active Pivot</h3>
                <div className={styles['skills-progess']} >
                  <div className={`${styles['skills-progess-bar']} ${styles['color-duck-blue']}`} role="progressbar" aria-valuenow="50" aria-valuemin="0" aria-valuemax="100" style={{ width: 50 + '%' }}>
                    <span>50%</span>
                  </div>
                </div>
              </div>

              <div className={styles['skills-progess-container']}>
                <h3>Angular 8</h3>
                <div className={styles['skills-progess']} >
                  <div className={`${styles['skills-progess-bar']} ${styles['color-duck-blue']}`} role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style={{ width: 70 + '%' }}>
                    <span>70%</span>
                  </div>
                </div>
              </div>

              <div className={styles['skills-progess-container']}>
                <h3>React</h3>
                <div className={styles['skills-progess']} >
                  <div className={`${styles['skills-progess-bar']} ${styles['color-duck-blue']}`} role="progressbar" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100" style={{ width: 40 + '%' }}>
                    <span>40%</span>
                  </div>
                </div>
              </div>


              <div className={styles['skills-progess-container']}>
                <h3>Hibernate</h3>
                <div className={styles['skills-progess']} >
                  <div className={`${styles['skills-progess-bar']} ${styles['color-duck-blue']}`} role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100" style={{ width: 60 + '%' }}>
                    <span>60%</span>
                  </div>
                </div>
              </div>

              <div className={styles['skills-progess-container']}>
                <h3>Mybatis</h3>
                <div className={styles['skills-progess']} >
                  <div className={`${styles['skills-progess-bar']} ${styles['color-duck-blue']}`} role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100" style={{ width: 60 + '%' }}>
                    <span>60%</span>
                  </div>
                </div>
              </div>
            </div>

            <div className={styles['skills-category-container']}>
              <h3 className={styles['skills-category-name']}>Application Server / Web Server</h3>

              <div className={styles['skills-progess-container']}>
                <h3>Tomcat</h3>
                <div className={styles['skills-progess']} >
                  <div className={`${styles['skills-progess-bar']} ${styles['color-dark-blue']}`} role="progressbar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100" style={{ width: 70 + '%' }}>
                    <span>80%</span>
                  </div>
                </div>
              </div>

              <div className={styles['skills-progess-container']}>
                <h3>Jboss</h3>
                <div className={styles['skills-progess']} >
                  <div className={`${styles['skills-progess-bar']} ${styles['color-dark-blue']}`} role="progressbar" aria-valuenow="65" aria-valuemin="0" aria-valuemax="100" style={{ width: 65 + '%' }}>
                    <span>65%</span>
                  </div>
                </div>
              </div>

              <div className={styles['skills-progess-container']}>
                <h3>WebSphere</h3>
                <div className={styles['skills-progess']} >
                  <div className={`${styles['skills-progess-bar']} ${styles['color-dark-blue']}`} role="progressbar" aria-valuenow="40" aria-valuemin="0" aria-valuemax="100" style={{ width: 40 + '%' }}>
                    <span>40%</span>
                  </div>
                </div>
              </div>

              <h3 className={styles['skills-category-name']}>SGBD</h3>

              <div className={styles['skills-progess-container']}>
                <h3>Oracle</h3>
                <div className={styles['skills-progess']} >
                  <div className={`${styles['skills-progess-bar']} ${styles['color-dark-green']}`} role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style={{ width: 70 + '%' }}>
                    <span>70%</span>
                  </div>
                </div>
              </div>

              <div className={styles['skills-progess-container']}>
                <h3>Sybase</h3>
                <div className={styles['skills-progess']} >
                  <div className={`${styles['skills-progess-bar']} ${styles['color-dark-green']}`} role="progressbar" aria-valuenow="65" aria-valuemin="0" aria-valuemax="100" style={{ width: 65 + '%' }}>
                    <span>65%</span>
                  </div>
                </div>
              </div>
            </div>

            <div className={styles['skills-category-container']}>
              <h3 className={styles['skills-category-name']}>Continous Integrations</h3>

              <div className={styles['skills-progess-container']}>
                <h3>Jenkins</h3>
                <div className={styles['skills-progess']} >
                  <div className={`${styles['skills-progess-bar']} ${styles['color-purple']}`} role="progressbar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100" style={{ width: 80 + '%' }}>
                    <span>80%</span>
                  </div>
                </div>
              </div>

              <div className={styles['skills-progess-container']}>
                <h3>Maven</h3>
                <div className={styles['skills-progess']} >
                  <div className={`${styles['skills-progess-bar']} ${styles['color-purple']}`} role="progressbar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100" style={{ width: 80 + '%' }}>
                    <span>80%</span>
                  </div>
                </div>
              </div>

              <div className={styles['skills-progess-container']}>
                <h3>Docker</h3>
                <div className={styles['skills-progess']} >
                  <div className={`${styles['skills-progess-bar']} ${styles['color-purple']}`} role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style={{ width: 70 + '%' }}>
                    <span>70%</span>
                  </div>
                </div>
              </div>

              <div className={styles['skills-progess-container']}>
                <h3>Kubernetes</h3>
                <div className={styles['skills-progess']} >
                  <div className={`${styles['skills-progess-bar']} ${styles['color-purple']}`} role="progressbar" aria-valuenow="70" aria-valuemin="0" aria-valuemax="100" style={{ width: 70 + '%' }}>
                    <span>70%</span>
                  </div>
                </div>
              </div>

              <div className={styles['skills-progess-container']}>
                <h3>Ansible</h3>
                <div className={styles['skills-progess']} >
                  <div className={`${styles['skills-progess-bar']} ${styles['color-purple']}`} role="progressbar" aria-valuenow="60" aria-valuemin="0" aria-valuemax="100" style={{ width: 60 + '%' }}>
                    <span>60%</span>
                  </div>
                </div>
              </div>

              <div className={styles['skills-progess-container']}>
                <h3>Git</h3>
                <div className={styles['skills-progess']} >
                  <div className={`${styles['skills-progess-bar']} ${styles['color-purple']}`} role="progressbar" aria-valuenow="80" aria-valuemin="0" aria-valuemax="100" style={{ width: 80 + '%' }}>
                    <span>80%</span>
                  </div>
                </div>
              </div>
            </div>


            <div className={styles['speech']}>
              <span>Here, you can find an overview of the quite high perception I have of my own skills, overestimated without any doubt, but it is regardless a totally non objective judgement. With some scales for you to subliminally give yourself an idea about my skills.</span>
            </div>
            <div className={styles['legend-timeline']}>
              <div className={styles['legend-timeline-container']}>
                <div className={styles['legend-entry']}>
                  <span data-percent="0 %" data-info="Need to explain...?"></span>
                </div>
                <div className={styles['legend-entry']}>
                  <span data-percent="30 %" data-info="Noob level"></span>
                </div>
                <div className={styles['legend-entry']}>
                  <span data-percent="60 %" data-info="Intermediate"></span>
                </div>
                <div className={styles['legend-entry']}>
                  <span data-percent="85 %" data-info="Advanced"></span>
                </div>
                <div className={styles['legend-entry']}>
                  <span data-percent="100 %" data-info="Expert"></span>
                </div>
                <div className={styles['legend-entry']}>
                  <span data-percent="150 %" data-info="You invented this damn technology"></span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </FadeIn>
    </section >
  );
};


MySkills.propTypes = {};

MySkills.defaultProps = {};

export default MySkills;
