import React from 'react';
import styles from './Experiences.module.scss';
import FadeIn from 'react-fade-in';

const Experiences = () => {

  return (
    <section id="about" data-testid="Experiences">
      <FadeIn delay="50" transitionDuration="3000">
        <div className={styles.Experiences}>

          <h2 className={styles['experiences-title']}>Work Experiences</h2>

          <div className={styles['timeline-container']}>
          <div className={styles['timeline-entry']}>
              <div className={styles['timeline-date']}>Since March 2021</div>
              <div className={styles['timeline-text']}>
                <h3><span className={styles['timeline-title-job']}>Java/Spark/Active Pivot Developer</span><span className={styles['timeline-title-client']}>Société Générale CIB</span></h3>
                <p>The project implements multiple tools to help to analyze the Value At Risk Chain. The chain uses a DataLake and Spark jobs to compute and store the data.  Then the Datalake feeds OLAP cube (ActivePivot) to expose VaR to users. I was a senior developer on the application, my main task was to implement evolutions, new processes and supporting the production.</p>
                <div className={styles['timeline-text-line']}><span>Environment: </span>Java 8, Spark, Active Pivot, SpringBoot, Angular 11, OpenSee, Python, Azure, Hibernate, Groovy, Oracle, PostGreSQL, ClickHouse, Jenkins, Maven, Docker, Kubernetes, Git, Nginx.</div>
              </div>
            </div>

            <div className={styles['timeline-entry']}>
              <div className={styles['timeline-date']}>May 2019 - February 2021</div>
              <div className={styles['timeline-text']}>
                <h3><span className={styles['timeline-title-job']}>Full Stack Java/Angular Developer</span><span className={styles['timeline-title-client']}>Crédit Agricole CIB</span></h3>
                <p>The project focuses on detecting and alerting on exceeding threshold on counterparty risk. It also provides a real time deal booking simulation API. I was part of the creation from scratch of an application on Angular 8 / Java 8 SpringBoot Rest API.</p>
                <div className={styles['timeline-text-line']}><span>Environment: </span>Java 8, JEE, SpringBoot, Struts, Angular 8, Hibernate, Groovy, Sybase, Jenkins, Maven, Docker, Kubernetes, Ansible, Git, Tomcat, JBoss, Nginx.</div>
              </div>
            </div>

            <div className={styles['timeline-entry']}>
              <div className={styles['timeline-date']}>October 2016 - May 2019</div>
              <div className={styles['timeline-text']}>
                <h3><span className={styles['timeline-title-job']}>Full Stack Java/Angular Lead Tech</span><span className={styles['timeline-title-client']}>Société Générale CIB</span></h3>
                <p>The project implements multiple tools to help calculating the Variation Margin and Initial Margin on Collateral Chain. First one, is a rules engine to certify valuations which are used on Variation Margin.  On  Initial Margin, it sets an OLAP cube (ActivePivot) to expose sensitivities to users. I was the technical lead of the application, whether it was for production support or implementing evolutions and new processes.</p>
                <div className={styles['timeline-text-line']}><span>Environment: </span>Java 8, SpringBoot/SpringCore, Active Pivot, Groovy, GWT, AngularJS, Tomcat, Oracle, Jenkins, Maven, Jira, Sonar, Drools, Git, XLDeploy, Liquibase.</div>
              </div>
            </div>

            <div className={styles['timeline-entry']}>
              <div className={styles['timeline-date']}>October 2014 - October 2016</div>
              <div className={styles['timeline-text']}>
                <h3><span className={styles['timeline-title-job']}>Java Developer</span><span className={styles['timeline-title-client']}>Crédit Agricole Retail Banking</span></h3>
                <p>The project sets up a Services Oriented Architecture system to provide the product Referential for Credit Agricole Retail Banking Information System. My role was to support Crédit Agricole team in order to take over the maintenance and evolution of the project.</p>
                <div className={styles['timeline-text-line']}><span>Environment: </span>Java 7, J2EE, Datastage, UNIX, WebSphere, DB2, Maven, Jax WS, DAO MyBatis, RSA, RTC, Jazz, JavaScript, Spring. </div>
              </div>
            </div>

            <div className={styles['timeline-entry']}>
              <div className={styles['timeline-date']}>December 2012 - October 2014</div>
              <div className={styles['timeline-text']}>
                <h3><span className={styles['timeline-title-job']}>Apprentice Java Developer</span><span className={styles['timeline-title-client']}>Crédit Agricole Retail Banking</span></h3>
                <p>The project provides a web process to suscribe to Crédit Agricole Retail Banking products. The project also sets up a Services Oriented Architecture system to provide the product Referential for the Information System. My role was to design and create the Web Services and develop the backend of the subscription process.</p>
                <div className={styles['timeline-text-line']}><span>Environment: </span>Java 7, J2EE, Datastage, UNIX, WebSphere, DB2, Maven, Jax WS, DAO MyBatis, RSA, RTC, Jazz, JavaScript, Spring. </div>
              </div>
            </div>

            <div className={styles['timeline-entry']}>
              <div className={styles['timeline-date']}>June 2012 - December 2012</div>
              <div className={styles['timeline-text']}>
                <h3><span className={styles['timeline-title-job']}>Apprentice Java Developer</span><span className={styles['timeline-title-client']}>Capeor</span></h3>
                <p>Project that sets up a web application that allows to suscribe on patrimonial investment products. My role was to develop the module that calculates commissions for the sales when they sell products to customers.</p>
                <div className={styles['timeline-text-line']}><span>Environment: </span> <span>JSF, RichFaces, EJB, Quartz, Hibernate, JLynx, Tomcat, JBoss, MySQL, OpenLDAP, Jenkins, Maven, JIRA, Sonar.</span></div>
              </div>
            </div>
          </div>
        </div>
      </FadeIn>
    </section>
  );
}

Experiences.propTypes = {};

Experiences.defaultProps = {};

export default Experiences;
