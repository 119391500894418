import React from 'react';
import styles from './Home.module.scss';
import Typed from 'react-typed';
import FadeIn from 'react-fade-in';


const Home = ({ fullpageApi }) => (

  <section className={styles.home} id="home" data-testid="Home">
    <div className={styles.pusher}></div>
    <FadeIn delay="50" transitionDuration="2000">
      <h1 className={styles.name}>Minh-Trieu HA</h1>
    </FadeIn>
    <FadeIn delay="1000" transitionDuration="2000">

      <h2 className={styles.title}>Yet another developer...</h2>
    </FadeIn>

    <FadeIn delay="300" transitionDuration="2000">

      <Typed className={styles['looping-position']}
        strings={[
          'Developer Java Backend',
          'Developer Angular Frontend',
          'Developer Java/Angular FullStack',
          'DevOps']}
        typeSpeed={100}
        backSpeed={30}
        loop
      />
    </FadeIn>
    <FadeIn delay="500" transitionDuration="2000">
      <div className={styles['home-footer']}>
        <span id={styles['home-footer-left']}>Created by Minh-Trieu HA</span>
        <div id={styles['home-footer-center']}>
          <div className={styles['scroll-down']} onClick={() => fullpageApi.moveSectionDown()}>
            <img className={styles['link']} src="assets/images/mouse-scroll.png" alt="mouse icon" />
            <span className={styles['link']}>Scroll Down</span>
          </div>
        </div>
        <div id={styles['home-footer-right']}>
          <span>Inspiration from <a href="https://www.free-css.com/">Free Css</a> and <a href="https://colorlib.com/">ColorLib</a>.</span>
          <br></br>
          <span>Builded and deployed with <a href="https://www.netlify.com/">Netlify</a>.</span>
        </div>
      </div>
    </FadeIn>
  </section>
);



Home.propTypes = {
};

Home.defaultProps = {};

export default Home;
